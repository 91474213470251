<script>
import { Table } from 'ant-design-vue'
import api from '@/command/api'
import { getAction, postAction, putAction } from '@/command/netTool'

export default {
  props: {
    roleData: {
      type: Array,
      default: () => {
        return []
      }
    },
    userId: null
  },
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
      arrRoleCode: [],
      haveRole: [],
      haveRoleData: [],
      columns: [
        {
          title: '角色名称',
          dataIndex: 'roleName'
        },
        {
          title: '角色代码',
          dataIndex: 'roleCode'
        },
        {
          title: '所属系统',
          dataIndex: 'sysCode',
          filters: this.roleData,
          onFilter: (value, record) => record.sysCode.indexOf(value) === 0,
          customRender: (text, records) => {
            return (this.roleData.find(e => e.appCode == text) || { appName: '' }).appName
          }
        }
      ]
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.haveRole,
        onChange: (selectedRowKeys, selectedRows) => {
          this.haveRole = selectedRowKeys
          this.arrRoleCode = selectedRows
        }
      }
    }
  },
  mounted() {
    postAction(`/role/user/${this.userId}`).then(res => {
      if (res.code == 200) {
        this.haveRole = res.data
        this.getDataInit()
      } else {
        this.$message.warning(res.msg)
      }
    })
  },
  methods: {
    getDataInit() {
      getAction('/api/role/list').then(res => {
        this.tableData = res.data
      })
    },
    sumbit() {
      //   if (this.arrRoleCode.length == 0) {
      //     this.$message.warning('请先勾选菜单！')
      //     return false
      //   } else {
      this.$emit('success', this.arrRoleCode)
      //   }
    }
  },
  render() {
    if (this.tableData.length > 0) {
      return (
        <Table rowSelection={this.rowSelection} dataSource={this.tableData} columns={this.columns} rowKey="roleCode" />
      )
    }
  }
}
</script>
